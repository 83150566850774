import 'semantic-ui-css/semantic.min.css'
import './style.scss';
import Header from './components/AppHeader';
import QueryTool from './components/QueryTool';
import Footer from './components/AppFooter';
import UrlTools from './components/UrlTools';
import { Divider } from 'semantic-ui-react';

function App() {
    const initAdmin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlAdmin = urlParams.get('admin');
    if (urlAdmin && (urlAdmin == '1' || urlAdmin?.toLocaleLowerCase() == 'true'))
      return (
        <div>
        <Divider/>
        <UrlTools />
        </div>
      );
    else
      return (<div></div>);
  };

  return (
    <div className='app'>
      <Header />
      <div className='app-content'>
        <QueryTool />
        {initAdmin()}
      </div>
      <Footer />
    </div>
  );
}

export default App;
