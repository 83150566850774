const insertUrl = async (url: string, product: string) => {
  const formData = new FormData();
  formData.append('url', url);
  formData.append('product', product);

  const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/loadUrl`, {
    mode: 'cors',
    method: 'POST',
    body: formData,
  });

  const responseText = response.text();
  return responseText;
};

export default insertUrl;
