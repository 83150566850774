import { useEffect, useState } from 'react';
import UrlUploader from './UrlUploader';
import UrlViewer from './UrlViewer';
import fetchUrls, { Document } from '../apis/fetchUrls';
import { Container } from 'semantic-ui-react';

const UrlTools = () => {
  const [refreshViewer, setRefreshViewer] = useState(false);
  const [urlList, setUrlList] = useState<Document[]>([]);

  // Get the list on first load
  useEffect(() => {
    fetchUrls().then((documents) => {
      setUrlList(documents);
    });
  }, []);

  useEffect(() => {
    if (refreshViewer) {
      setRefreshViewer(false);
      fetchUrls().then((documents) => {
        setUrlList(documents);
      });
    }
  }, [refreshViewer]);

  return (
    <Container className='document-tools'>
      <UrlUploader setRefreshViewer={setRefreshViewer} />
      <UrlViewer urlList={urlList} />
    </Container>
  );
};

export default UrlTools;
