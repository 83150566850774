const rateResponse = async (responseRatingsId:string, rating:string, userName:string, userExplenation:string) => {
  const formData = new FormData();
  formData.append('responseRatingsId', responseRatingsId);
  formData.append('rating', rating);
  formData.append('userName', userName);
  formData.append('userExplenation', userExplenation);

  const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/rateResponse`, {
    mode: 'cors',
    method: 'POST',
    body: formData,
  });

  const responseText = response.status;
  return responseText;
};

export default rateResponse;
