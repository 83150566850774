import type { Document } from '../apis/fetchUrls';

const MAX_TITLE_LENGTH = 70;
const MAX_URL_LENGTH = 150;

type URLViewerProps = {
  urlList: Document[];
};

const UrlViewer = ({ urlList }: URLViewerProps) => {
  const prepend = (array: JSX.Element[], value: JSX.Element): JSX.Element[] => {
    const newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  };

  let urlListElems = urlList.map((document) => {
    // TODO - redo trimming using CSS and text-overflow: ellipsis
    const id =
      document.id.length < MAX_TITLE_LENGTH
        ? document.id
        : document.id.substring(0, MAX_TITLE_LENGTH) + '...';
    const text =
      document.text.length < MAX_URL_LENGTH
        ? document.text
        : document.text.substring(0, MAX_URL_LENGTH) + '...';
    return (
      <div key={document.id} className='viewer__list__item'>
        <p className='viewer__list__title'>{id}</p>
        <p className='viewer__list__text'>{text}</p>
      </div>
    );
  });

  // prepend header
  urlListElems = prepend(
    urlListElems,
    <div key='viewer_title' className='viewer__list__item'>
      <p className='viewer__list__header'>My Urls</p>
    </div>,
  );

  return (
    <div className='viewer'>
      <div className='viewer__list'>
        {urlListElems.length > 0 ? (
          urlListElems
        ) : (
          <div>
            <p className='viewer__list__title'>Upload your first document!</p>
            <p className='viewer__list__text'>
              You will see the title and content here
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrlViewer;
