export interface ResponseSources {
  doc_id: string;
  start: number;
  end: number;
  similarity: number;
  url: string;
  title: string;
  text: string;
};

export interface QueryResponse {
  stream: ReadableStreamDefaultReader<Uint8Array>;
  responseRatingsId: string;
  sources: ResponseSources[];
};

export interface ChatMessage {
    role: string;
    content: string;
    additional_kwargs: Record<string, string | Record<string, never>>;
}


const queryIndex = async (query: string, product: string, history: ChatMessage[]): Promise<QueryResponse> => {
  const queryURL = new URL(`${process.env.REACT_APP_BACKEND_SERVICE}/query?`);
  queryURL.searchParams.append('text', query);
  queryURL.searchParams.append('product', product);
  queryURL.searchParams.append('history', JSON.stringify(history));

  const response = await fetch(queryURL, { mode: 'cors' });

  if (!response.ok || response.body == null) {
    const emptyReader = new ReadableStream().getReader();
    return { stream: emptyReader, responseRatingsId: '', sources: []};
  }
   const handleSourceNodes = (sourceNodes: string | null) => {
    return sourceNodes != null ? JSON.parse(sourceNodes) : [];
   };
  const sourceNodesHeader = response.headers?.get('source_nodes');
  const sourceNodes = handleSourceNodes(sourceNodesHeader);
  
  return { stream: response.body.getReader(), responseRatingsId: response.headers?.get('request_id') ?? '0', sources: sourceNodes};
};

export default queryIndex;
