import { SyntheticEvent, useState, useRef, ChangeEvent } from 'react';
import { Label, Button, Container, Dimmer, Form, Icon, TextArea, DropdownProps, Grid, Rating, RatingProps, Transition, Message, TextAreaProps } from 'semantic-ui-react'
import queryUrlIndex, { ResponseSources, ChatMessage } from '../apis/queryUrlIndex';
import rateResponse from '../apis/queryRating';

// Img imports for products
import proad from '../assets/images/proad.svg'
import dnp from '../assets/images/dnp.svg'
import teambox from '../assets/images/teambox.svg'
import hellohq from '../assets/images/hellohq.svg'

const QueryTool = () => {

  const Products = [
    {
      key: 'dnp',
      value: 'dnp',
      image: { avatar: false, src: dnp },
    },
    {
      key: 'hellohq',
      value: 'hellohq',
      image: { avatar: false, src: hellohq },
    },
    {
      key: 'proad',
      value: 'proad',
      image: { avatar: false, src: proad },
    },
    {
      key: 'teambox',
      value: 'teambox',
      image: { avatar: false, src: teambox },
    },
  ];

  const initProduct = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlProduct = urlParams.get('product')
    if (urlProduct && Products.find((item) => { return item.value == urlProduct }))
      return urlProduct;
    else
      return 'dnp';
  }

  const [isLoading, setLoading] = useState(false);
  const [isRateable, setisRateable] = useState(false);
  const [query, setQuery] = useState('');
  const [userName, setUserName] = useState('');
  const [product, setProduct] = useState(initProduct());
  const [responseText, setResponseText] = useState('');
  const [responseSources, setResponseSources] = useState<ResponseSources[]>([]);
  const [responseRatingsId, setResponseRatingsId] = useState('');
  const [starRating, setStarRating] = useState('3');
  const [userExplanation, setUserExplanation] = useState('');
  const ratingRef = useRef<HTMLInputElement>(null);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>(() => []);


  const setQueryHandler = (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
    setQuery(data.value?.toString() || ''); // Achte darauf, dass data.value möglicherweise undefined ist
  };

  const onQueryKeyDown = (event: React.SyntheticEvent<HTMLElement>) => {
    const keyboardEvent = event.nativeEvent as KeyboardEvent;
    if (keyboardEvent.ctrlKey && keyboardEvent.key === 'Enter') {
      submitQuery();
    }
  }

  const setNameHandler = (event: React.ChangeEvent<HTMLElement>) => {
    setUserName((event.target as HTMLInputElement).value)
  }

  const setExplanationHandler = (event: React.SyntheticEvent<HTMLElement>) => {
    setUserExplanation((event.target as HTMLInputElement).value);
  }

  const setProductHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    if (data.value) {
      setProduct(data.value.toString());
      // reset Answer and Rating after Product change
      setResponseText('');
      setResponseRatingsId('');
      setStarRating('3');
      setUserName('');
      setUserExplanation('');
      setResponseSources([]);
      setisRateable(false);
      // Handle URL changes
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('product', data.value.toString());
      history.pushState(null, '', '?' + urlParams.toString());
    }
  }

  const submitQuery = () => {
    if (query) {
      setLoading(true);
      setResponseText('');
      setResponseRatingsId('');
      setResponseSources([]);
      setisRateable(false);
      setFeedbackSent(false);
      queryUrlIndex(query, product, chatHistory).then((response) => {
        handleResponseStream(response.stream)
        setResponseRatingsId(response.responseRatingsId)
      });
    }
  };

const handleResponseStream = async (stream: ReadableStreamDefaultReader) => {
  let read = true;
  let buffer = '';
  let sourceNodes: ResponseSources[] = [];

  const sourceNodesStartTag = 'source_nodes:'
  const sourceNodesEndTag = '@soure_nodes_end@';
  const chatHistoryStartTag = 'chat_history:';
  const chatHistoryEndTag = '@chat_history_end@';

  while (read) {
    const { done, value } = await stream.read();

    if (done) {
      read = false;
      setisRateable(true);
      setLoading(false);
    }

    const chunk = new TextDecoder().decode(value);
    buffer += chunk;

    const sourceEndIndex = buffer.indexOf(sourceNodesEndTag) + sourceNodesEndTag.length;
    const sourceMessage = buffer.substring(sourceNodesStartTag.length, sourceEndIndex - sourceNodesEndTag.length);

    sourceNodes = JSON.parse(sourceMessage) as ResponseSources[];

    const historyStartIndex = buffer.indexOf(chatHistoryStartTag) > 0 ? buffer.indexOf(chatHistoryStartTag) : buffer.length;


    if (sourceEndIndex<historyStartIndex-1) {
        const message = buffer.substring(sourceEndIndex, historyStartIndex - 1);
        setResponseText(message);
    }

    const historyEndIndex = buffer.indexOf(chatHistoryEndTag) > 0 ? buffer.indexOf(chatHistoryEndTag) + chatHistoryEndTag.length : sourceEndIndex;

    if (historyEndIndex > historyStartIndex && historyStartIndex !== -1 && historyEndIndex !== -1) {
      const rawHistoryString = buffer.substring(historyStartIndex, historyEndIndex - chatHistoryEndTag.length).replace(chatHistoryStartTag, '').trim();
      const historyData = JSON.parse(rawHistoryString) as ChatMessage[];
      setChatHistory(historyData);
    }
  }

  // Remove duplicates from sourceNodes
  const cleanedSource = sourceNodes.filter(
    (obj: { doc_id: string }, index: number) =>
      sourceNodes.findIndex((item) => item.doc_id === obj.doc_id) === index
  );
  setResponseSources(cleanedSource);

  // Make sure to close the stream reader upon completion
  stream.releaseLock();
};


  const submitRating = () => {
    rateResponse(responseRatingsId, starRating, userName, userExplanation).then(() => {
      setShowToast(true);
      setLoading(false);
      setTimeout(()=>{setShowToast(false);
        setFeedbackSent(true);},1250);
    });
  };

  const changeRating = (event: SyntheticEvent) => {
    if (event == null || event.target == null || !(event.target instanceof HTMLElement)) {
      return null;
    }
    const value = event.target.getAttribute('aria-posinset')
    if (value == null) {
      return null;
    }
    const rating = value.toString()
    // We use setRating for the correct value incase of use of the submit button.
    setStarRating(rating);
    // We use the new value, because starRating does not yet contain the correct value.
    rateResponse(responseRatingsId, rating, userName, userExplanation).then(() => {
      setLoading(false);
    });
    const element = document.getElementById('rating-details');
    if (element == null)
      return null;
    element.scrollIntoView();

    return true;
  }

  const sources = responseSources.map((source, index) => {
    if (index < 3 && !responseSources.find((src,id)=>{return source.url==src.url && index>id;}))
      return (
        <Label circular key={source.doc_id}><a href={source.url} target='__blank'>Open Source</a></Label>
      );
  });

  const textAreaStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    resize: 'none'
  };

  return (
    <Container
      className='app-query-container'
      textAlign='center'>
      <h1 >everii Al Knowledgebase</h1>
      <Form className='app-query' name='form-query' onSubmit={submitQuery}>
        <Form.Dropdown
          placeholder='Select Product'
          icon='angle down'
          disabled={isLoading}
          options={Products}
          onChange={setProductHandler}
          value={product}
          button
        />

        <div className="everii-long-user-input">
          <Form.TextArea
            placeholder='Stelle deine Frage...'
            disabled={isLoading}
            onKeyDown={onQueryKeyDown}
            onChange={setQueryHandler}
            value={query}
            rows={3} // Anzahl der Zeilen festlegen
            style={textAreaStyle}
            tabindex="0"
          />
          <Icon name='send' size='large' link onClick={submitQuery} width={1} />
        </div>
      </Form>
      <Form className='app-answer'>
        <Dimmer.Dimmable>
          <TextArea
            rows={20}
            placeholder='Unsere Antwort für dich...'
            value={responseText}
          />
        </Dimmer.Dimmable>
        <Grid columns={2} relaxed='very'>
          <Grid.Column className='app-helpful' textAlign='left'>
            <Label >War die Antwort hilfreich?</Label>
            <Button.Group>
              <Rating disabled={isLoading} icon='star' ref={ratingRef as React.RefObject<unknown> as React.RefObject<React.Component<RatingProps>>} onRate={changeRating} value={starRating} defaultRating={3} maxRating={5} />
            </Button.Group>
          </Grid.Column>
          <Grid.Column className='app-sources' textAlign='right'>
            {sources}
          </Grid.Column>
        </Grid>
        <div id='rating-details'>
          <Transition  visible={showToast} animation='scale' duration={500}>
            <Message id='app-feedback-toast' positive>{feedbackSent?'Dein Feedback wurde aktualisiert"':'Vielen Dank für dein Feedback. ♥'}</Message>
          </Transition>
          <Label id='feedback-label'>Feedback</Label>
          <Grid columns={3} relaxed='very' id='rating-details-grid'>
            <Grid.Column className='app-rating-details app-rating-details' textAlign='right'>
              <div className="everii-long-user-input">
                <Form.TextArea
                  placeholder='Bitte füge Details hinzu...'
                  onChange={setExplanationHandler}
                  value={userExplanation}
                  rows={3}
                  style={textAreaStyle}
                  tabindex="0"
                />
              </div>
            </Grid.Column>
            <Grid.Column className='app-rating-additional-infos' textAlign='left'>
              <Form.Input
                placeholder='Bitte gib deine E-Mail ein...'
                onChange={setNameHandler}
                value={userName}

                className='rating-name'
              />
            </Grid.Column>
            <Grid.Column className='app-rating-submit' textAlign='left'>
              <Icon name='send'
                disabled={!isRateable}
                size='large'
                link onClick={submitRating}
                style={{ display: 'flex', float: 'right' }}
                width={1}
              />
            </Grid.Column>
          </Grid>
        </div>
      </Form>
    </Container>
  );
};

export default QueryTool;
