import { Container } from 'semantic-ui-react'

const AppFooter = () => {
  return (
    <Container className='app-footer' textAlign='right'>
     <a className='app-imprint' href='https://www.everii.io/de/impressum' target='__blank'>Imprint</a> <span>© everii Group GmbH, 2023</span>
    </Container>
  );
};

export default AppFooter;
