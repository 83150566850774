import { Header } from 'semantic-ui-react'
import everiiLogo from '../assets/images/everii-group-logo.svg';

const AppHeader = () => {
  return (
    <Header className='app-header'>
        <img src={everiiLogo}/>
    </Header>
  );
};

export default AppHeader;
