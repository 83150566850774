export type Document = {
  id: string;
  text: string;
};

const fetchUrls = async (): Promise<Document[]> => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/getUrls`, { mode: 'cors' });

  if (!response.ok) {
    return [];
  }

  const documentList = (await response.json()) as Document[];
  return documentList;
};

export default fetchUrls;
