import { ChangeEvent, useState } from 'react';
import insertUrl from '../apis/insertUrl';
import { Button, Container, Form } from 'semantic-ui-react';

interface HTMLInputEvent extends ChangeEvent {
  target: HTMLInputElement & EventTarget;
}

type UrlUploaderProps = {
  setRefreshViewer: (refresh: boolean) => void;
};

const UrlUploader = ({ setRefreshViewer }: UrlUploaderProps) => {
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isUrlPicked, setIsUrlPicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeUrlHandler = (event: HTMLInputEvent) => {
    if (event.target && event.target.value) {
      setIsUrlPicked(true);
    } else {
      setIsUrlPicked(false);
    }
    setSelectedUrl(event.target.value);
  };
  const changeProductHandler = (event: HTMLInputEvent) => {
    if (event.target && event.target.value) {
      setSelectedProduct(event.target.value);
    }
  };

  const handleSubmission = () => {
    // Todo
    if (selectedUrl) {
      setIsLoading(true);
      insertUrl(selectedUrl, selectedProduct).then(() => {
        setRefreshViewer(true);
        setSelectedUrl('');
        setSelectedProduct('');
        setIsUrlPicked(false);
        setIsLoading(false);
      });
    }
  };

  return (
    <Container
      className='app-indexer-container'
      textAlign='center'>
      <h1 >Add a url to index</h1>
      <Form className='app-indexer' name='form-indexer'>
        <Form.Input
          placeholder='Url'
          value={selectedUrl}
          onChange={changeUrlHandler}
        />
        <Form.Input
          fluid
          placeholder='Product name'
          value={selectedProduct}
          onChange={changeProductHandler}
        />
        <Button disabled={!isUrlPicked && isLoading} loading={isLoading} onClick={handleSubmission}>Submit</Button>
      </Form>

    </Container>
  )
};

export default UrlUploader;
